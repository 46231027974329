import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Revenues | How revenue works",
  "includeToc": true,
  "description": "Companies pay a wide range of fees, rates, and taxes to extract natural resources in the U.S. The amounts differ depending on the ownership of the resources. We'll cover some of the major types of payments companies make here. They are usually called “revenue” because they represent revenue to the American public.",
  "tags": ["How revenue works", "revenues", "federal land", "federal water", "extraction", "corporate income tax", "tax expenditures", "federal budget process"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GlossaryTerm = makeShortcode("GlossaryTerm");
const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const Box = makeShortcode("Box");
const FederalBudgetProcessImg = makeShortcode("FederalBudgetProcessImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works /`}</a></p>

    <br />
    <h1 {...{
      "id": "Revenues",
      "style": {
        "position": "relative"
      }
    }}>{`Revenues`}</h1>
    <p>{`Companies pay a wide range of fees, rates, and taxes to extract natural resources in the U.S. The amounts differ depending on the ownership of the resources. We'll cover some of the major types of payments companies make here. They are usually called “revenue” because they represent revenue to the American public.`}</p>
    <h2 {...{
      "id": "Payments-to-extract-natural-resources-from-federal-land-and-waters",
      "style": {
        "position": "relative"
      }
    }}>{`Payments to extract natural resources from federal land and waters`}</h2>
    <p>{`Companies that extract natural resources on federal lands and waters pay revenue to the Department of the Interior (DOI). Companies pay `}<GlossaryTerm mdxType="GlossaryTerm">{`bonuses`}</GlossaryTerm>{`, `}<GlossaryTerm mdxType="GlossaryTerm">{`rents`}</GlossaryTerm>{`, `}<GlossaryTerm mdxType="GlossaryTerm">{`royalties`}</GlossaryTerm>{`, or fees and penalties (if incurred) to `}<GlossaryTerm mdxType="GlossaryTerm">{`ONRR`}</GlossaryTerm>{`. In some cases, bonuses and rents are paid to the Bureau of Land Management. Royalties, a percentage of the sales value of extracted resources, make up most of the revenue paid to DOI.`}</p>
    <br />
Lease holders also pay different fees to the Bureau of Land Management, Bureau of Safety and Environmental Enforcement, and Bureau of Ocean Energy Management. They often reimburse the federal government for costs associated with awarding, administering, and enforcing leases. Companies pay fees for extracting locatable hardrock minerals on federal lands, but not royalties, under the Mining Law of 1872.
    <h3 {...{
      "id": "Revenue-streams-and-rates",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue streams and rates`}</h3>
    <h4 {...{
      "id": "Oil-and-gas",
      "style": {
        "position": "relative"
      }
    }}>{`Oil and gas`}</h4>
    <Table mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell style={{
            "borderBottom": "none"
          }} mdxType="TableCell"></TableCell>
      <TableCell colSpan="3" style={{
            "textAlign": "center"
          }} mdxType="TableCell">Phase</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Before Production</TableCell>
      <TableCell mdxType="TableCell">During Production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Onshore</TableCell>
      <TableCell mdxType="TableCell"><strong>Bonus</strong></TableCell>
      <TableCell mdxType="TableCell"><strong>$1.50</strong> annual rent per acre for the first 5 years, <strong>$2</strong> annual rent per acre thereafter</TableCell>
      <TableCell mdxType="TableCell"><strong>12.5%</strong> of production value in royalties</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Offshore</TableCell>
      <TableCell mdxType="TableCell"><strong>Bonus</strong></TableCell>
      <TableCell mdxType="TableCell"><strong>Water depth 0–200m:</strong> Years 1–5 rent is $7/acre, year 6 rent is $14/acre, year 7 rent is $21/acre, year 8+ rent is $28/acre<br />
      <strong>Water depth 200–400m:</strong> Years 1–5 rent is $11/acre, year 6 rent is $22/acre, year 7 rent is $33/acre, year 8+ rent is $44/acre<br />
      <strong>Water depth 400+m:</strong> Years 1–5 rent is $11/acre, years 6+ rent is $16/acre
      </TableCell>
      <TableCell mdxType="TableCell"><strong>12.5%</strong> for leases located in water depths less than 200 meters<br />
      <strong>18.75%</strong> for leases located in water depths of 200 meters and deeper
      </TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h4 {...{
      "id": "Coal",
      "style": {
        "position": "relative"
      }
    }}>{`Coal`}</h4>
    <Table className="article_table" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell style={{
            "borderBottom": "none"
          }} mdxType="TableCell"></TableCell>
      <TableCell colSpan="3" style={{
            "textAlign": "center"
          }} mdxType="TableCell">Phase</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Before production</TableCell>
      <TableCell mdxType="TableCell">During production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Surface</TableCell>
      <TableCell mdxType="TableCell"><strong>Bonus</strong></TableCell>
      <TableCell mdxType="TableCell"><strong>$3</strong> annual rent per acre</TableCell>
      <TableCell mdxType="TableCell"><strong>12.5%</strong> of production value in royalties<br />
      <strong>$0.28</strong> per ton Abandoned Mine Land Fee
      </TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Sub-surface</TableCell>
      <TableCell mdxType="TableCell"><strong>Bonus</strong></TableCell>
      <TableCell mdxType="TableCell"><strong>$3</strong> annual rent per acre</TableCell>
      <TableCell mdxType="TableCell"><strong>8%</strong> of production value in royalties<br />
      <strong>$0.12</strong> per ton Abandoned Mine Land Fee
      </TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h4 {...{
      "id": "Hardrock-minerals",
      "style": {
        "position": "relative"
      }
    }}>{`Hardrock minerals`}</h4>
    <Table className="article_table" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell style={{
            "borderBottom": "none"
          }} mdxType="TableCell"></TableCell>
      <TableCell colSpan="3" style={{
            "textAlign": "center"
          }} mdxType="TableCell">Phase</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Before production</TableCell>
      <TableCell mdxType="TableCell">During production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Public domain lands</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell"><strong>$1</strong> per acre<br />
          <strong>$20</strong> processing fee<br />
          <strong>$40</strong> location fee<br />
          <strong>$165</strong> initial maintenance fee<br />
          <strong>$165</strong> annual maintenance fee for the assessment year in which the claim/site was located
      </TableCell>
      <TableCell mdxType="TableCell"></TableCell>
    </TableRow>
      <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Acquired lands</TableCell>
      <TableCell mdxType="TableCell"><strong>$1,000</strong> minimum bond for prospecting permits<br />
          <strong>$5,000</strong> minimum bond for leases</TableCell>
      <TableCell mdxType="TableCell"><strong>$0.50</strong> per acre or fraction of an acre</TableCell>
      <TableCell mdxType="TableCell">Royalty rates are exempt from minimums and determined on an individual basis by the authorized leasing officers.</TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h4 {...{
      "id": "Solar-and-wind-energy",
      "style": {
        "position": "relative"
      }
    }}>{`Solar and wind energy`}</h4>
    <Table className="article_table" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell style={{
            "borderBottom": "none"
          }} mdxType="TableCell"></TableCell>
      <TableCell colSpan="3" style={{
            "textAlign": "center"
          }} mdxType="TableCell">Phase</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Before production</TableCell>
      <TableCell mdxType="TableCell">During production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Onshore (solar and wind)</TableCell>
      <TableCell mdxType="TableCell">
        <strong>$15</strong> per acre application filing fee (outside designated leasing areas)
      </TableCell>
      <TableCell mdxType="TableCell"><strong>Rent</strong> determined by number of acres multiplied by zone rate<br />
      <a href="https://www.federalregister.gov/documents/2016/12/19/2016-27551/competitive-processes-terms-and-conditions-for-leasing-public-lands-for-solar-and-wind-energy">(Review the BLM rule) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a><br /><br />
      <strong>$2,863</strong> to <strong>$4,294</strong> for solar megawatt capacity fees, varying based on technology<br />
      <strong>$5,010</strong> megawatt capacity fee for wind<br />
      <a href="https://www.blm.gov/policy/im-2017-096">(Review the BLM instruction memorandum) <OpenInNewIcon style={{
                fontSize: 20
              }} mdxType="OpenInNewIcon" /></a>
      </TableCell>
      <TableCell mdxType="TableCell"> </TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Offshore (wind)</TableCell>
      <TableCell mdxType="TableCell"><strong>Bonus</strong> (competitive lease)<br />
          <strong>$0.25</strong> per acre for acquisition fee (uncompetitive lease)</TableCell>
      <TableCell mdxType="TableCell"><strong>$3</strong> annual rent per acre</TableCell>
      <TableCell mdxType="TableCell"><strong>2%</strong> of anticipated value of wind energy produced in operating fee unless waived or otherwise specified<br />
      </TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h4 {...{
      "id": "Geothermal",
      "style": {
        "position": "relative"
      }
    }}>{`Geothermal`}</h4>
    <Table className="article_table" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell style={{
            "borderBottom": "none"
          }} mdxType="TableCell"></TableCell>
      <TableCell colSpan="3" style={{
            "textAlign": "center"
          }} mdxType="TableCell">Phase</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Before production</TableCell>
      <TableCell mdxType="TableCell">During production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Competitive leasing</TableCell>
      <TableCell mdxType="TableCell">
        <strong>$115</strong> nomination fee and <strong>$0.12</strong> per acre<br />
        <strong>$165</strong> application fee
      </TableCell>
      <TableCell mdxType="TableCell"><strong>$2</strong> rent per acre for the first year<br />
          <strong>$3</strong> annual rent per acre for years 2–10<br />
          <strong>$5</strong> annual rent per acre after 10 years<br />
        Direct-use fees for energy needs other than the commercial production or generation of electricity
      </TableCell>
      <TableCell mdxType="TableCell">Electricity sales: <strong>1.75%</strong> of gross proceeds for 10 years, then <strong>3.5%</strong><br /><br />
          Arm’s length sales: <strong>10%</strong> of gross proceeds from contract multiplied by lease royalty rate
      </TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Noncompetitive leasing</TableCell>
      <TableCell mdxType="TableCell"><strong>$415</strong> application fee</TableCell>
      <TableCell mdxType="TableCell"><strong>$1</strong> annual rent per acre for 10 years<br />
          <strong>$5</strong> annual rent per acre thereafter
      </TableCell>
      <TableCell mdxType="TableCell"></TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <h2 {...{
      "id": "Payments-to-extract-natural-resources-from-any-land-or-water-in-the-US",
      "style": {
        "position": "relative"
      }
    }}>{`Payments to extract natural resources from any land or water in the U.S.`}</h2>
    <h3 {...{
      "id": "Corporate-income-taxes",
      "style": {
        "position": "relative"
      }
    }}>{`Corporate income taxes`}</h3>
    <p>{`Corporations operating in the extractive industries pay taxes to the IRS on their income. These companies pay federal corporate income taxes regardless of whether they extract natural resources from federal, state, or privately held lands, so long as they have a liability. These companies also pay taxes on income from extracting natural resources and processing them into other products and commodities. There are different types of companies operating in these industries, with different ownership structures. As a result, they are treated as different taxpayers:`}</p>
    <ul>
      <li parentName="ul">{`C-corporations with many shareholders who own the company. These companies pay corporate income taxes to the IRS.`}</li>
      <li parentName="ul">{`S-corporations with 100 shareholders or less who own the company. Shareholders pay personal income taxes to the IRS.`}</li>
      <li parentName="ul">{`Partnerships where two or more members own the business. Members individually pay income taxes to the IRS.`}</li>
      <li parentName="ul">{`Sole proprietorships with one individual owner. The individual owner pays personal income tax to the IRS.`}</li>
    </ul>
    <h3 {...{
      "id": "Other-taxes-and-fees",
      "style": {
        "position": "relative"
      }
    }}>{`Other taxes and fees`}</h3>
    <p>{`In the U.S., coal producers must pay a federal `}<a parentName="p" {...{
        "href": "/how-revenue-works/coal-excise-tax/"
      }}>{`coal excise tax`}</a>{` when they mine coal (a producer is any person or entity that owns the coal after it’s mined from the ground). Producers pay the tax when the coal is first sold or used. The tax does not apply to lignite or to coal mined in the U.S. for export. The `}<a parentName="p" {...{
        "href": "/how-revenue-works/aml-reclamation-program"
      }}>{`Abandoned Mine Land (AML) Reclamation Program`}</a>{` uses fees paid by present-day coal mining companies to `}<GlossaryTerm mdxType="GlossaryTerm">{`reclaim`}</GlossaryTerm>{` coal mines abandoned before 1977.`}</p>
    <h3 {...{
      "id": "Revenue-policy-provisions",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue policy provisions`}</h3>
    <p>{`While royalty rates can reach as high as 18.75%, and the federal corporate income tax rate can reach as high as 21% depending on company income, companies may pay less. Revenue policy provisions, including royalty relief and tax expenditures, can result in smaller revenue and tax payments to the federal government to promote other policy goals.`}</p>
    <h4 {...{
      "id": "Royalty-relief",
      "style": {
        "position": "relative"
      }
    }}>{`Royalty relief`}</h4>
    <p>{`To create incentives for companies to produce additional oil and gas on certain leases on the Outer Continental Shelf where extraction is anticipated to be unprofitable, the federal government may grant some lease holders royalty relief. Royalty relief means that these lease holders do not have to pay royalties on some amount of production, or they pay a smaller percentage of royalties, for the oil and gas they extract. There are four situations in which a lease holder may gain royalty relief:`}</p>
    <ul>
      <li parentName="ul">{`Leases in deep waters with depths greater than 200 meters in the Gulf of Mexico. (This type of relief has not been offered for several years, though existing leases currently include it.)`}</li>
      <li parentName="ul">{`Leases in shallow waters with depths under 400 meters for deep gas production.`}</li>
      <li parentName="ul">{`Leases toward the end of their lives in which halving royalties would encourage additional production.`}</li>
      <li parentName="ul">{`In special cases, continued production under existing terms is projected to be unprofitable.`}</li>
    </ul>
    <p>{`Oil and gas prices can rise above certain thresholds. In some of these situations, leaseholders that previously gained royalty relief must start paying royalties at the regular rate.`}</p>
    <h3 {...{
      "id": "Tax-expenditures",
      "style": {
        "position": "relative"
      }
    }}>{`Tax expenditures`}</h3>
    <p>{`Tax expenditures are “revenue losses attributable to provisions of the federal tax laws which allow a special exclusion, exemption, or deduction from gross income or which provide a special credit, a preferential rate of tax, or a deferral of tax liability.” These exceptions may be considered alternatives to other policy instruments, such as spending or regulatory programs. The Treasury estimates the total dollar amount of each tax expenditure in a given year. It publishes a `}<a parentName="p" {...{
        "href": "https://home.treasury.gov/policy-issues/tax-policy/tax-expenditures"
      }}>{`report of these estimates `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <h2 {...{
      "id": "After-a-payment-what-happens-to-the-revenue",
      "style": {
        "position": "relative"
      }
    }}>{`After a payment, what happens to the revenue?`}</h2>
    <h3 {...{
      "id": "Federal-budget-process",
      "style": {
        "position": "relative"
      }
    }}>{`Federal budget process`}</h3>
    <p>{`The federal government collects the revenue. Next, it passes through a series of budgetary gateways before funding public services and community development. These gateways are described below. You can `}<a parentName="p" {...{
        "href": "/explore?dataType=Disbursements"
      }}>{`explore disbursement data`}</a>{`.`}</p>
    <Box mt={4} mb={4} mdxType="Box">
      <FederalBudgetProcessImg mdxType="FederalBudgetProcessImg" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      